<template>
  <div class="d-flex flex-column flex-root">
    <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-row-auto bgi-size-contain bgi-no-repeat bgi-position-bottom p-10 p-lg-15"
        :style="{
          backgroundImage: `url(${backgroundImage})`,
        }"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--begin: Aside header -->
          <router-link to="/" v-slot="{ href, navigate }">
            <a :href="href" class="flex-column-auto" @click="navigate">
              <img :src="logo" width="50" />
            </a>
          </router-link>
          <!--end: Aside header -->
          <!--begin: Aside content -->
          <div class="flex-column-fluid d-flex flex-column justify-content-center">
            <h3 class="font-size-h1 mt-10 mb-5">
              Welcome to App Express!
            </h3>
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-15">
            <div class="opacity-70 font-weight-bold">
              © 2020 App Express
            </div>
            <!-- <div class="d-flex">
              <router-link
                  to="/contacts"
                  v-slot="{ href, navigate }"
              >
                <a
                    :href="href"
                    class="ml-10"
                    @click="navigate"
                >
                  Contact
                </a>
              </router-link>
            </div> -->
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
        <div class="d-lg-flex flex-column-fluid flex-lg-center mt-20 mt-lg-0">
          <router-view></router-view>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/styles/pages/login/login-1.scss';
</style>

<script>
import { mapState } from 'vuex';

export default {
  name: 'auth',
  data() {
    return {
      logo: 'media/logos/logo.png',
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      // media/bg/bg-4.jpg
      return process.env.BASE_URL + 'media/svg/illustrations/visuals.svg';
    },
  },
};
</script>
